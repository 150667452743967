<template>
  <div>
    
    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <router-link
            to="/master-data/role"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
          <li class="nav-item">
            <a
              class="nav-link"
              role="tab"
              :href="href" 
              @click="navigate"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <span class="svg-icon svg-icon-md svg-icon-success m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Navigation/Left-2.svg"/>
                <!--end::Svg Icon-->
              </span>
              Kembali
            </a>
          </li>
          </router-link>
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              role="tab"
            >
              Edit Role
            </a>
          </li>
        </ul>
      </div>

      <!--begin::Form-->
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div v-if="dataOri">
                <ValidationObserver ref="formAdd">
                <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Kode</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Kode" rules="required|min_value:1|max_value:4000000000" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-role-1" 
                                  label-for="input-role-1"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-input
                                    id="input-role-2"
                                    v-model="form.role"
                                    class="form-control form-control-solid"
                                    autocomplete="off"
                                    required
                                    readonly
                                    disabled
                                    size="lg"
                                    placeholder="Kode"
                                    :state="valid"
                                    ></b-form-input>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Nama Role</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Nama Role" rules="required|min:2|max:50" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-role-2" 
                                  label-for="input-role-2"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-input
                                    id="input-role-2"
                                    v-model="form.name"
                                    class="form-control form-control-solid"
                                    autocomplete="off"
                                    required
                                    size="lg"
                                    placeholder="Nama Role"
                                    :state="valid"
                                    ></b-form-input>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>

                  <div class="table-responsive pt-10">
                    <table class="table b-table table-borderless">
                      <thead class="thead-light">
                        <tr>
                          <th class="font-weight-bold text-left " v-for="heading in ['Module', 'Read', 'Create', 'Update', 'Delete']" :key="heading">{{ heading }}</th>
                        </tr>
                      </thead>

                      <tr v-for="(val, name) in form.permissions" :key="name">
                        <td class="">{{ name | title }}</td>
                        <td class="">
                          <b-form-checkbox size="lg" v-model="val['view']" ></b-form-checkbox>
                        </td>
                        <td class="">
                          <b-form-checkbox size="lg" v-if="!listNotShow.includes(name)" v-model="val['create']" ></b-form-checkbox>
                        </td>
                        <td class="">
                          <b-form-checkbox size="lg" v-if="!listNotShow.includes(name)" v-model="val['edit']" ></b-form-checkbox>
                        </td>
                        <td class="">
                          <b-form-checkbox size="lg" v-if="!listNotShow.includes(name)" v-model="val['delete']" ></b-form-checkbox>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div class="separator separator-solid my-5 mt-15"></div>

                  <div class="mt-10">
                    <button 
                    @click="onSubmit" 
                    ref="kt_submit"
                    class="btn btn-primary font-weight-bold">
                      <i class="la la-save"></i>
                      Perbarui
                    </button>

                    &nbsp;

                    <button
                    @click="onReset"
                    class="btn btn-clean font-weight-bold">
                      <i class="la la-recycle"></i>
                      Reset
                    </button>
                  </div>
                  
                </b-form>
                </ValidationObserver>  
              </div>
              <div v-else>
                <BulletListLoader></BulletListLoader>
              </div>          
            </b-tab>
          </b-tabs>
        </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BulletListLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RoleService from "@/core/services/api/master/role.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "master-data-role-edit",
  props: {
    idRL: {
      required: true
    }
  },
  components: {
    BulletListLoader
  },
  data() {
    return {
      tabIndex: 0,
      dataOri: null,
      form: {
        role: Number(this.idRL),
        name: null,
        permissions: {
            'full_code': {
                'view': false,
            },
            'profil': {
                'view': true,
            },
            'cutting_he': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'afkir_dini': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'verifikasi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'statistik': {
                'view': false,
            },
            'perusahaan': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'farm': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'hatchery': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'peternak': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'peternak_farm': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'wilayah': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            }, 
            'surat_edaran': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'role': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'user': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'nasional_populasi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'nasional_produksi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'nasional_distribusi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'komersil_populasi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'komersil_distribusi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
        },
      },
      listNotShow: ['full_code', 'profil', 'statistik'],
      show: true,
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    
    getData () {
      RoleService.getRoleSingle({id: this.idRL})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.dataOri = JSON.stringify(res.data.data)
            this.form = res.data.data
        })
        .catch((err) => ErrorService.status(err))
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            RoleService.updateRole(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.dataOri = JSON.stringify(this.form)
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset() {
      // Reset our form values
      
      this.form = JSON.parse(this.dataOri)
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Role" }]);
    if (!this.currentUser.menu.role) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()
  },
};
</script>
