import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA ROLE------
    getRole(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/role", { params: credentials })
    },
    getAllRole() {
        ApiService.setHeader();
        return ApiService.get("v2/master/role/all")
    },
    getRoleSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/role/single", { params: credentials })
    },
    addRole(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/master/role", credentials)
    },
    updateRole(credentials) {
        ApiService.setHeader();
        return ApiService.put(`v2/master/role`, credentials)
    },
    deleteRole(credentials) {
        ApiService.setHeader();
        return ApiService.delete(`v2/master/role/`, { data: credentials })
    },

}